<template>
	<div class="bg-gray-light pb-10">
		<div
			:style="'background-image: url(' + item_cartel.cartl_mobile_img_url + '); background-position: center center; background-size: cover; '"
			style="width: 100%; height: 150px"
			class="position-relative"
		>
			<div class="position-absolute-full" style="background-color: rgba(0, 0, 0, 0.2)"></div>
			<div
				class="position-absolute color-white text-right"
				style="right: 20px; bottom: 20px;"
			>
				<div class="size-px-16 font-weight-700 text-shadow">{{ item_cartel.cartl_level_name }}</div>
				<div class="size-px-14 text-shadow">{{ $language.common.memberCount }} <span class="size-px-14 font-weight-700">{{  item_cartel.current_member_count }}</span></div>
			</div>
		</div>

		<div class="prl-20 bg-white pb-10">
			<div class="position-relative justify-end">
				<div class="position-absolute" style="left: 0px; top: -40px; width: 80px; height: 80px; overflow: hidden"><img :src="item_cartel.cartl_profle_img_url" style="width: 100%; height: 100%; border-radius: 100%; object-fit: cover" @error="$bus.$emit('onErrorImage', $event)" /></div>
				<div class="pt-5">
					<button
						v-if="is_join_confirm"
						@click="toAlarm"
					>
						<span class="badge_30 mr-10 badge_30_notice_bl"></span> <!-- 초대 -->
					</button>
					<button
						v-if="is_join_confirm"
						@click="toJoin"
					>
						<span class="badge_30 mr-10 badge_30_invite_bl"></span> <!-- 초대 -->
					</button>
					<button
						v-else-if="is_join_wait"
					>
						<div
							class="btn_s btn_fill_blue mr-10"
						>
							<span>{{  item_cartel.cartl_entry_state_name }} <!-- 가입 대기 --> </span>
						</div>
					</button>
					<button
						v-else-if="is_join_cancel"
					>
						<div
							class="btn_s btn_fill_gray color-white mr-10"
						>
							<span>{{  item_cartel.cartl_entry_state_name }} <!-- 가입 취소 --></span>
						</div>
					</button>
					<button
						v-else
					>
						<div
							class="btn_s btn_fill_gray mr-10"
						>
							<span>{{  $language.common.not_join }} <!-- 미가입 --></span>
						</div>
					</button>
					<button
						v-if="is_join_confirm && !is_subscribe_manager"
						@click="onSupport"
						class="flex-1 color-white size-px-14 bg-red pa-5-10 radius-20 "
						:disabled="is_disabled_support"
					><img :src="require('@/assets/image/icon_shake.svg')" /> {{ $language.cartel_new.title_support }} <!-- 후원하기 --> </button>
				</div>
			</div>

			<div class="title_area mt-20">
				<div class=" justify-start items-center">
					<span class="badge_30 mr-10" :class="'badge_30_' + item_cartel.type + '_bl'"></span>
					<h2 class="size-px-18">{{  item_cartel.cartl_name }}</h2>
				</div>

				<div class="mt-20 size-px-14 font-weight-400 pb-10">{{ item_cartel.cartl_desctn}}</div>
			</div>

			<div
				v-if="false && !is_subscribe_manager"
				class="mt-20 justify-space-between"
			>
				<button
					v-if="!is_subscribe_manager"
					@click="onSupport"
					class="flex-1 color-white size-px-14 bg-red pa-10-20 radius-20 mr-10"
					:disabled="is_disabled_support"
				><img :src="require('@/assets/image/icon_shake.svg')" /> {{ $language.cartel_new.title_support }} <!-- 후원하기 --> </button>
				<button
					v-if="is_subscribe"
					class="flex-1 color-white size-px-12 bg-blue pa-10-20 radius-20"
					@click="onSubscribe"
				><img :src="require('@/assets/image/icon_sub.svg')" /> {{ subscribe_last }} <!-- ~일 후 종료 --> </button>
				<button
					v-else
					class="flex-1 color-white size-px-14 bg-blue pa-10-20 radius-20"
					@click="onSubscribe"
					:disabled="is_disabled_subscribe"
				><img :src="require('@/assets/image/icon_sub.svg')" /> {{ $language.cartel_new.title_subscribe }} <!-- 구독하기 --> </button>
			</div>

			<div
				v-if="false && is_subscribe_manager"
				class="mt-20 justify-space-between"
			>
				<button
					@click="toSubscribePlane"
					class="flex-1 color-white size-px-14 bg-blue pa-10-20 radius-20 mr-10"
				><img :src="require('@/assets/image/icon_sub.svg')" /> {{ $language.cartel_new.title_plane_management }} <!--  플랜관리 --></button>
				<button
					v-if="is_subscribe_manager"
					class="flex-1 color-white size-px-14 bg-blue pa-10-20 radius-20 "
					@click="toSubscribeManagement"
				><img :src="require('@/assets/image/icon_sub.svg')" /> {{ $language.cartel_new.title_subscribe_management }} <!--  구독관리 --></button>
			</div>

			<div
				v-if="false" class="mt-20 justify-space-between"
			>
				<button
					@click="toSubscribeSetting"
					class="flex-1 color-white size-px-14 bg-blue pa-10-20 radius-20"
				><img :src="require('@/assets/image/icon_sub.svg')" /> {{ $language.subscribe.btn_subscribe_add }} <!--   구독 게시판 --></button>
			</div>
		</div>

		<CartelSupport
			v-if="is_on_support"
			:user="user"
			:item_cartel="item_cartel"

			@click="is_on_support = false"
			@cancel="is_on_support = false"
		></CartelSupport>

		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:plan_info="item_cartel.member_cartl_subscrp_plan_info"

			@click="is_on_subscribe = false"
			@cancel="is_on_subscribe = false"
		></CartelSubscribe>
	</div>
</template>

<script>

	import CartelSupport from "@/view/Cartel/CartelSupport";
	import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";

	export default {
		name: 'CartelInfo'
		, components: {CartelSubscribe, CartelSupport}
		, props: ['user', 'item_cartel']
		, data: () => {
			return {
				is_on_support: false
				, item_support: {
					text: ''
					, point: ''
				}
				, is_on_support_result: false
				, is_on_subscribe: false
				, is_on_subscribe_update: false
			}
		}
		, computed: {

			is_join: function(){
				let t = false
				if(this.item_cartel.cartl_entry_state_code == ''){
					t = true
				}
				return t
			}
			, is_join_confirm: function(){
				let t = false
				switch(this.item_cartel.cartl_entry_state_code) {
					case 'CA00300004':
						t = true
						break
				}
				return t
			}
			, is_join_cancel: function (){
				let t = false
				switch(this.item_cartel.cartl_entry_state_code){
					case 'CA00300003':
					case 'CA00300005':
					case 'CA00300006':
					case 'CA00300007':
						t = true
						break;
				}
				return t
			}
			, is_join_wait: function(){
				let t = false
				switch(this.item_cartel.cartl_entry_state_code){
					case 'CA00300001':
					case 'CA00300002':
						t = true
						break;
				}
				return t
			}
			, is_subscribe_manager: function(){
				let t = false
				if(this.item_cartel.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, is_subscribe: function(){
				let t = false
				if(this.item_cartel.member_cartl_subscrp_fg == 'Y'){
					t = true
				}
				return t
			}
			, subscribe_last: function(){
				let t = ''
				let now = new Date()
				let last = new Date(this.item_cartel.member_cartl_subscrp_plan_info.member_subscrp_enddate)
				// last = new Date('2023-01-01')

				let diff = Math.abs(now.getTime() - last.getTime());
				t = Math.ceil(diff / (1000 * 60 * 60 * 24));

				return t + this.$language.cartel_new.txt_finish_plan
			}
			, is_disabled_support: function(){
				let t = true
				if(this.is_join_confirm){
					t = false
				}
				return t
			}
			, is_disabled_subscribe: function(){
				let t = true
				if(this.is_join_confirm){
					t = false
				}
				return t
			}
		}
		, methods: {
			onSupport: function(){
				this.is_on_support = true
			}
			, offSupport: function(){
				this.item_support = {
					text: '', point: 0
				}
				this.is_on_support = false
			}
			, onSubscribe: function(){
				this.is_on_subscribe = true
			}
			, onSubscribeUpdate: function(){
				this.is_on_subscribe_update = true
			}
			, toJoin: function(){
				if(this.user.member_number){
					this.$bus.$emit('to', { name: 'mafia057', params: { idx: this.item_cartel.cartl_number }})
				}else{
					this.$bus.$emit('onLogin')
				}
			}
			, toSubscribeSetting: function(){
				this.$bus.$emit('to', { name: 'SubscribeSetting', params: { idx: this.item_cartel.cartl_number}})
			}
			, toSubscribeManagement: function(){
				this.$bus.$emit('to', { name: 'SubscribeManagement', params: { idx: this.item_cartel.cartl_number}})
			}
			, toSubscribePlane: function(){
				this.$bus.$emit('to', { name: 'SubscribePlane', params: { idx: this.item_cartel.cartl_number}})
			}
			, toAlarm: function(){
				this.$bus.$emit('to', { name: 'mafia076'})
			}
		}
		, created() {

		}
	}
</script>

<style>

	.color-red  input::placeholder{
		color: var(--red)
	}
	.color-gray textarea::placeholder{
		color: var(--gray01)
	}
</style>