<template>
	<div
		v-if="list_items.length > 0"
	>
		<div
			class="overflow-hidden bg-white"
		>
			<div
				class="justify-space-between prl-20 mt-10 size-px-13"
			>
				<span class="size-px-16 color-blue font-weight-700">{{ $language.voting.title_voting}}</span>
				<button
					@click="toVotingList"
					class="color-gray"
				>더보기</button>
			</div>
			<ul class="pa-10 overflow-x-auto white-space-nowrap" >
				<li
					v-for="item in list_items"
					:key="'item_' + item.cartl_vote_number"
					class="box radius-20 color-white position-relative mb-10 mr-10 mt-10 inline-block"
					style="height: 200px; width: calc(100% - 20px)"
				>
					<div
						class="full-height"
					>
						<div
							v-for="(image, i_index) in item.vote_thumbnail_img.vote_thumbnail_img_partial_list"
							:key="'image_' + i_index"
							:class="{ 'full-height' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
							class="overflow-hidden"
						>
							<img :src="image.vote_thumbnail_img_url" :class="{ 'object-cover' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }" class="width-100"/>
						</div>
						<div
							class="position-absolute-full flex-column justify-space-between "
							@click="toDetail(item)"
						>
							<div class="pa-20 full-height text-center flex-column justify-center " style="  background-color: rgba( 0, 0, 0, 0.3 );">
								<div class="size-px-16 font-weight-600">{{ item.vote_title | maxLength(30, '...')}}</div>


								<hr class="top-line width-40 ma-auto mt-10 mb-10" />

								<div class="size-px-13">{{ item.sDate}} ~ {{ item.eDate }}</div>

							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'CartelVotingTop'
		, props: ['user', 'cartel_info']
		, components: {}
		, data: function(){
			return {
				items_voting: []
				, item_voting: {

				}
				, items: []
			}
		}
		, computed: {

			list_items: function(){
				return this.items.filter( (item) => {
					item.bg_img = item.vote_original_img.vote_original_img_url

					item.sDate = item.vote_startdt.slice(0, 16)
					item.eDate = item.vote_enddate.slice(0, 16)
					/*
					let sDate = item.vote_startdt?.split('T')
					item.sDate = sDate[0] + ' ' + sDate[1]?.slice(0, 5)
					let eDate = item.vote_enddate?.split('T')
					item.eDate = eDate[0] + ' ' + eDate[1]?.slice(0, 5)
					*
					 */
					return item
				})
			}
		}
		, methods: {
			getVoting: async function(){
				try{

					let url = this.$api_url.api_path.get_open_vote_list
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_board_list
					}
					const result = await this.$Request({
						method: 'post'
						,url: url
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, vote_release_settup_fg: ''
						}
						, type: true
					})

					if(result.success){
						this.items_voting = result.data.cartl_vote_board_list
						if(this.items_voting.length > 0){
							this.item_voting = this.items_voting[0]
							await this.getData()
						}
					}else{
						throw result.message
					}
				}catch(e){
					// this.$bus.$emit('notify',  { type: 'error', message: e})
					// console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_voting_list_ing
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_list_ing
					}

					const result = await this.$Request({
						method: 'post'
						,url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.item_voting.board_number
							, type: this.type
							, page_number: 1
							, pagerecnum: 10
						}
						, type: true
					})
					if(result.success){
						this.items = result.data.cartl_vote_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toDetail: function(item){
				if(this.user.member_number){

					if(item.cartl_vote_state_code == 'CA03700002' || item.cartl_vote_state_code == 'CA03700006'){
						this.$bus.$emit('to', { name: 'CartelVotingInfo', params: { idx: this.$route.params.idx, v_id: this.item_voting.board_number, e_id: item.cartl_vote_number, from: this.$route.fullPath }})
					}else{
						this.$bus.$emit('to', { name: 'CartelVotingEdit', from: this.$route.fullPath, params: { idx: this.$route.params.idx, v_id: this.item_voting.board_number, e_id: item.cartl_vote_number, from: this.$route.fullPath }})
					}
				}else{
					this.$bus.$emit('onLogin')
				}
			}
			, toVotingList: function(){

				// 사용자 목록
				let d = { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: this.item_voting.board_number, type: 'ing', from: this.$route.name }}

				// 관리 목록
				if(this.cartel_info.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001' || (this.item_voting.admin_authority_settup_fg == 'Y' && this.cartel_info.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500002')){
					d = { name: 'CartelVotingList', params: { idx: this.$route.params.idx, v_id: this.item_voting.board_number, type: 'ing', from: this.$route.name }}
				}
				this.$bus.$emit('to', d)
			}
		}
		, created() {
			this.getVoting()
		}
	}
</script>

<style>
	.white-space-nowrap > li{
		margin-left: 10px;
	}
</style>