<template>
	<div
		ref="post_list_item_view"
	>
		<div class="header">
			<div
				class="top top_mafi "
				:class="{ trans: is_trans}"
			><!--trans-->

				<h1 :class="[{ logo2: is_trans},{'display-none': !is_trans}]">
					<button
						v-if="$route.params.from"
						@click="toMain"
					><v-icon class="color-white" large>mdi-chevron-left</v-icon></button>
					<a
						v-else
						@click="toMain"
					><span class="hide">Culture M.A.F.I.A.</span></a>
				</h1>

				<div
					v-if="!is_trans"
					class="util shadow mr-10"
				>
					<button
						@click="$emit('to', { name: 'main'})"
						class="arrow_white"
					>
						<v-icon class="color-white">mdi-home-outline</v-icon>
					</button>
				</div>
				<h2 class="page_tit" v-if="!is_trans" style="width: 170px !important; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">{{ item_cartel.cartl_name }}</h2>
				<div class="util shadow">
					<a
						@click="to048"
						class="img_button2 mr-20"
					><span class="hide">{{ $language.common.search }}</span></a>
					<button
						v-if="is_menu"
						@click="is_side = !is_side"
						class="ico_ham"
					><span class="hide">{{ $language.common.menu }}</span></button>
				</div>
			</div>
		</div>

		<CartelInfo
			v-if="item_cartel.cartl_number"
			:user="user"
			:item_cartel="item_cartel"
		></CartelInfo>

		<div
			v-if="false"
			class="c_notice" ref="notice"
		>
			<div class="container">
				<div class="row">
					<h3 class="tit">{{ $language.common.notice }}</h3>
					<div class="c_notice_list">
						<ul>
							<template
								v-if="items_cartel_notice.length > 0"
							>
								<template
									v-for="(notice, index) in items_cartel_notice"
								>
									<li
										v-if="index < 3"
										:key="'notice_' + index"

										@click="$emit('to', { name: 'mafia058', params: { idx: $route.params.idx, code: notice.board_type_code, b_id: notice.board_number, bbs_id: notice.bulletin_number}})"
									>
										<em>{{ $language.common.announcement }}</em>
										<span>{{ notice.post_title }}</span>
									</li>
								</template>
							</template>
							<li
								v-else
							>
								<em>{{ $language.common.announcement }}</em>
								<span>{{ $language.common.NotFoundNotice }}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div
			class="post_wrap pb-100" :class="{ 'mt-50': $route.params.code}"
		>
			<div class="container">
				<LiveTop
					v-if="false"
					:user="user"
					:cartel_info="item_cartel"
				></LiveTop>

				<CartelVotingtop
					:user="user"
					:cartel_info="item_cartel"

					class="bg-gray-light pb-10"
				></CartelVotingtop>

				<div
					class="justify-space-between size-px-16 text-center"
				>
					<div class="flex-1 pa-10 under-line" :class="{ blue: view_type == 'post' }" @click="view_type='post'">포스트</div>
					<div class="flex-1 pa-10 under-line" :class="{ blue: view_type == 'subscribe' }" @click="view_type='subscribe'">구독 플랜</div>
				</div>

				<div class="position-relative full-height">
					<CartelBbsList
						v-show="view_type == 'post'"
						:user="user"
						:items_list="items_cartel_bbs"
						:max="max"
						:is_add="is_add"
						:item_search="item_search"
						:item_cartel="item_cartel"
						:from="'cartel'"

						@click="update"
					></CartelBbsList>

					<CartelMainSubscribe
						v-if="view_type == 'subscribe'"

						:user="user"
						:cartel_info="item_cartel"

						class="position-absolute full-height bg-white width-100 size-px-14"
						style="top: 0; left: 0;"
					></CartelMainSubscribe>
				</div>
			</div>
		</div>

		<CartelNaviage
			:user="user"
			:item_cartel="item_cartel"
		></CartelNaviage>

		<!-- 사이드 메뉴 -->
		<mafia055
			v-if="is_side"
			:user="user"
			:cartel="item_cartel"
			:live_info="cartl_member_grade_chg_info"

			@to="to"
			@click="setMount"
			@cancel="is_side = false"
		></mafia055>

		<!-- 회원 정보 -->
		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<!-- 더보기 -->
		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="onModify"
			@block="doBlock"
			style="z-index: 1003"
		>
		</mafia064>

		<!-- 게시물 삭제 팝업 -->
		<popup_confirm
			v-if="is_delete"

			@click="deleteBbs"
			@cancel="is_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.notice.article_delete }}
			</template>
			<template
				v-slot:main-txt
			>{{ $language.notice.article_delete_message }}
			</template>
			<template
				v-slot:sub-txt
			>{{ $language.notice.article_delete_message_confirm }}
			</template>
		</popup_confirm>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getCartelBbs"
		></ScrollPagination>

		<!-- 게시물 수정 -->
		<mafia1272
			v-if="is_item_modify"

			:user="user"
			:cartel="item_cartel"
			:board="item_board"
			:bbs="item_more"

			@cancel="is_item_modify = false"
			@click="modify"
			class="layer-popup"
		></mafia1272>

		<!-- 게시물 검색 -->
		<mafia048
			v-if="is_048"
			:user="user"
			:cartel="item_cartel"

			class="layer-popup"

			@cancel="offSearch"
			@goBack="offSearch"
		></mafia048>
	</div>

</template>


<script>

import mafia055 from '@/view/Cartel/mafia055'
import mafia064 from "@/view/Cartel/mafia064";
import popup_confirm from "@/view/Layout/PopupConfirm";
import mafia098 from '@/view/Cartel/mafia098'
import ScrollPagination from "@/components/ScrollPagination";
import Mafia1272 from "@/view/CartelBoard/mafia127-2";
import mafia048 from "@/view/Cartel/mafia048";
import CartelNaviage from "@/view/Cartel/CartelNavigate";
import LiveTop from "@/view/LiveChat/LiveTop";
import CartelInfo from "@/view/Cartel/CartelInfo";
import CartelBbsList from "@/view/Cartel/CartelBbsList";
import CartelMainSubscribe from "@/view/Cartel/CartelMainSubscribe";
import CartelVotingtop from "@/view/Cartel/CartelVotingTop";

export default {
	name: 'mafia049'
	, props: ['user']
	, components: {
		CartelVotingtop,
		CartelMainSubscribe,
		CartelBbsList,
		LiveTop,
		CartelInfo,
		CartelNaviage,
		Mafia1272,
		mafia064, mafia055, popup_confirm, mafia098, ScrollPagination, mafia048
	}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: this.$language.common.cartel
				, type: 'cartel'
				, not_header: true
				, params: this.$route.params
			}
			, item_cartel: {}
			, items_cartel: []
			, items_cartel_notice: []
			, items_cartel_bbs: []
			, idx_cartel: this.$route.params.idx
			, is_side: false
			, is_more: false
			, item_more: {}
			, item_board: {}
			, item_detail: {}
			, is_delete: false
			, is_098: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_search: {
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			}
			, scroll_ready: false
			, max: false
			, is_add: true
			, is_trans: true
			, is_item_detail: false
			, is_item_detail_comment: false
			, is_item_modify: false
			, item_profile: { }
			, is_048: false
			, hash: this.$route.hash
			, cartl_member_grade_chg_info: []
			, is_on_subscribe_permission: false
			, is_on_subscribe: false
			, notice_mttrs_list_page: {
				page_number: 1
				, pagerecnum: 10
			}
			, post_list_page: {
				page_number: 1
				, pagerecnum: 10
			}
			, view_type: 'post'
		}
	}
	, computed: {
		is_write: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == 'CA00300004'){
				t = true
			}
			return t
		}

		, is_subscribe: function(){
			let t = false
			if(this.item_cartel.cartl_member_grade_chg_info.member_cartl_subscrp_fg == 'Y'){
				t = true
			}
			return t
		}

		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_join_confirm: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true
					break
			}
			return t
		}
		, is_join_cancel: function (){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true
					break;
			}
			return t
		}
		, is_join_wait: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300001':
				case 'CA00300002':
					t = true
					break;
			}
			return t
		}
		, is_menu: function(){
			let t = false

			if(this.item_cartel.cartl_entry_state_code == 'CA00300004'){
				t = true
			}

			t = true

			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{

				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.get_open_cartel_info
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_info
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: this.notice_mttrs_list_page
						, post_list_page: this.post_list_page
						, page_number: this.post_list_page.page_number
						, pagerecnum: this.post_list_page.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.$set(this.item_cartel, 'cartl_number', this.$route.params.idx)
					this.items_cartel_notice = result.data.notice_mttrs_list
					this.items_cartel_bbs = result.data.post_list
					this.cartl_member_grade_chg_info = result.data.cartl_member_grade_chg_info

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

					this.$storage.setQuery(this.item_search)

					if(result.data.post_list.length > 0){
						this.max = false
					}else{
						this.max = true
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelCheer: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelNotice: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelBbs: async function(page){
			try{
				if(this.view_type != 'post'){
					return false
				}
				//this.$bus.$emit('on', true)
				this.is_add = false
				this.$set(this.item_search, 'page_number', page)

				let url = this.$api_url.api_path.get_open_cartel_bbs_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_bbs_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					if(result.data.post_list.length > 0){
						this.items_cartel_bbs = this.items_cartel_bbs.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
				this.is_add = true
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, onDelete: function(){
			this.is_more = false
			this.is_delete = true
		}
		, handleClick(event, ref) {
			// console.log(this.$refs, event, ref)
			this.$refs[ref][event]();
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			this.is_delete = false
			await this.mount()
		}
		, setMount: function(e){
			this.$emit('to', e)
			if(e.name == 'mafia049'){
				this.mount()
			}
		}

		, mount: async function(){
			this.$emit('onLoad', this.program)

			this.is_side = false

			await this.getCartel()
		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
					item.sympaty_count++
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item, index){

			if(!item.is_block){
				if(item.board_type_code == 'CA00700005' && item.member_post_subscrp_fg != 'Y'){
					this.is_on_subscribe_permission = true
				}else{

					this.item_more = item
					this.item_more.index = index

					// this.is_item_detail = true
					this.is_item_detail_comment = false

					let path = this.$route.path.split('#')

					if(path[1] == 'detail'){
						this.$router.push(path[0])
					}else{
						this.$router.push(this.$route.path + '#detail')
					}
				}
			}
			// document.location.href = '#detail'

			// this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number, from: 'mafia049'}})
		}
		, toComment: function(item, index){
			if(!item.is_block){
				this.item_more = item
				this.item_more.index = index

				// this.is_item_detail = true
				// this.is_item_detail_comment = true

				this.$router.push(this.$route.path + '#comment')
				// this.$bus.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
			}
		}
		, to: function(params){
			this.$emit('to', params)
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false

			this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}

		, getBbs: function(item){
			switch (item.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					this.getCartelCheerInfo(item)
					break
				case 'CA00700005':
					this.getCartelSubscribe(item)
					break
				default:
					this.getCartelBbsInfo(item)
					break
			}
		}
		, getCartelSubscribe: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							await this.getRealFile(val, key)
						}
					}

					if(this.item_bbs.post_subscrp_plan_list.length > 0){
						this.setPlan(this.item_bbs.post_subscrp_plan_list[0])
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}

				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: item.board_type_code == 'CA00700005' ? this.$api_url.api_path.get_subscribe_board_info : this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							this.item_more.post_file_list[i].post_appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

					this.$set(this.items_cartel_bbs, this.item_more.index, this.item_more)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.item_bbs.is_comment = this.item_bbs.comment_writing_fg == 'Y' ? true : false

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							val.key = key
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs[this.item_more.index] = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, update: function(item){

			console.log('update', item.index, item)
			this.$set(this.items_cartel_bbs, item.index, {})
			this.$set(this.items_cartel_bbs, item.index, item)
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}
		, to048: function(){
			if(this.user.member_number){
				this.$bus.$emit('to', { name: 'mafia048', params: {idx: this.$route.params.idx}})
			}else{
				this.$bus.$emit('onLogin')
			}
		}
		, doBlock: function(type){

			this.items_cartel_bbs = this.items_cartel_bbs.filter((item) => {
				if(item.post_member_number == this.item_more.post_member_number){
					item.blok_member_fg = type ? 'Y' : 'N'
				}
				return item
			})
		}
		, onSubscribe: function(){
			this.is_on_subscribe_permission = false
			this.is_on_subscribe = true
		}
		, offSubscribe: function(){
			this.is_on_subscribe = false
		}
		, offSearch: function(){
			this.is_048 = false
			this.$bus.$emit('to', { name: this.$route.name })
		}
		, toMain: function(){

			if(this.$route.params.from){
				this.$bus.$emit('to', {path: this.$route.params.from})
			}else{
				this.$bus.$emit('to', {name: 'main'})
			}
		}
	}
	,async  mounted() {
		await this.mount()

		document.body.addEventListener('scroll', () => {
			if(document.body.scrollTop > 0){
				this.is_trans = false
			}else{
				this.is_trans = true
			}
		});

		// console.log('049', this.$route.params)

	}
}
</script>

<style>
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }
.bg-gray { background-color: #eee}

.label {}
.label-danger { background-color: var(--red); color: var(--white); letter-spacing: 1px}
</style>