<template>
	<div
		class="bg_wh"
		ref="post_list_item_view"
	>
		<div class="header filter">
			<div class="top top_wh">
				<div class="util">
					<button
						class="arrow"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="header_search">
					<input
						v-model="item_search.search_value"
						type="text" :placeholder="$language.common.input_search_keyword"
						maxlength="50"
						@keyup.enter="getSearch"
					>
				</div>
				<div class="header_search_btn">
					<div
						v-if="item_search.search_value"
						class="btn_del"
					>
						<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
						<button
							@click="item_search.search_value = ''; is_search = false"
							class="active"
						><span class="hide">{{ $language.common.close }}</span></button>
					</div>
					<div class="util">
						<button
							@click="getSearch"
							class="img_button"
						><span class="hide">{{ $language.common.search }}</span></button>
					</div>
				</div>
			</div>
		</div>


		<div
			class="section_wrap pt-50"  style="height: 100vh"
		>
			<div
				v-if="view_type == 'member'"
				class="thumbnail_wrap" style="padding-top: 5px"
			>
				<div class="container">
					<div class="row">
						<ul class="thumbnail_list">
							<h3 class="list_tit" style="padding-bottom: 7px !important;">{{ $language.common.searchResultUser }}</h3>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
							>
								<div class="circle_thumbnail_item">
									<a
										@click="on098(item)"
										class="clear"
									>
										<div class="picture">
											<img
												v-if="item.profle_nft_card_img_url"
												:src="$request.upload_url(item.profle_nft_card_img_url)" :alt="item.nickname"
											>
											<img
												v-else
												:src="require('@/assets/image/@noimage.png')" :alt="item.nickname"
											>
										</div>
										<div class="text_area">
											<strong>{{ item.nickname }}</strong>
											<p class="textzone">{{ item.memo }}</p>
										</div>
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- 게시물 리스트 -->
			<div
				class="thumbnail_wrap" style="height: 50vh"
			>
				<div
					class="post_wrap pb-100" :class="{ 'mt-50': $route.params.code}"
				>
					<div
						class="container"
					>
						<div
							v-if="view_type != 'member'"
							class="user"
						>
							<div class="container">
								<div class="row">
									<div class="user_tit">
										<h3>{{ $language.common.searchResultUser }}</h3>
										<a
											v-if="items_member.length > 0"
											@click="view_type = 'member'"
										><i class="icon-arrow-right"><span class="hide">{{ $language.common.searchResultUser }} {{ $language.common.seeMore }}</span></i></a>
									</div>
								</div>
							</div>
							<div class="user_result">
								<div class="container">
									<template
										v-if="items_member.length > 0"
									>
										<div class="swiper cartel_view" style="overflow: auto; padding-bottom: 10px">
											<div class="swiper-wrapper" style="display: flex; justify-content: start">
												<div
													v-for="(item, index) in items_member"
													:key="'item_' + index"
													class="swiper-slide" style="flex-basis: 64px; margin-right: 10px"
												>
													<div class="cartel_item">
														<a
															@click="on098(item)"
														>
															<div class="picture">
																<img
																	v-if="item.profle_nft_card_img_url"
																	:src="$request.upload_url(item.profle_nft_card_img_url)"
																	:alt="item.nickname"
																/>
																<img
																	v-else
																	:src="require('@/assets/image/@noimage.png')" :alt="item.nickname"
																/>
															</div>
															<span class="name">{{  item.nickname }}</span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</template>

									<div
										v-else
										style="text-align: center; font-size: 14px; padding: 10px 0;"
									>
										{{ $language.common.no_search_data }}
									</div>
								</div>
							</div>
						</div>

						<template
							v-if="view_type != 'member'"
						>
							<CartelBbsList
								:user="user"
								:items_list="items_bbs"
								:max="max"
								:is_add="is_add"
								:item_search="item_search"
								:item_cartel="cartel"
								:from="'mafia048'"
							></CartelBbsList>
						</template>
					</div>
				</div>
			</div>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="items_bbs"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99; background-color: white"
		></mafia098>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getBbsList"
		></ScrollPagination>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="mount"
			@deleteBbs="mount"
			@modify="onModify"

			:user="user"
			:item_bbs="item_more"
			style="z-index: 1003"
		>
		</mafia064>

		<mafia1272
			v-if="is_item_modify"

			:user="user"
			:cartel="cartel"
			:bbs="item_more"

			@cancel="is_item_modify = false"
			@click="modify"
			class="layer-popup"
		></mafia1272>

		<cartel_bbs_detail
			v-if="is_item_detail"

			:cartel="cartel"
			:user="user"
			:bbs="item_more"
			:is_item_detail_comment="is_item_detail_comment"

			@cancel="update"
			@toModify="onModify"
			@deleteBbs="deleteBbs"
			class="layer-popup"
		></cartel_bbs_detail>
	</div>
</template>

<script>
import mafia098 from "@/view/Cartel/mafia098"
import ScrollPagination from "@/components/ScrollPagination";
import mafia064 from "@/view/Cartel/mafia064";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
import mafia1272 from "@/view/CartelBoard/mafia127-2";
import CartelBbsList from "@/view/Cartel/CartelBbsList";

export default {
	name: 'mafia048'
	, props: ['user', 'cartel']
	, components: {CartelBbsList, mafia064, mafia098, ScrollPagination, cartel_bbs_detail, mafia1272}
	, data: function(){
		return {
			program: {
				name: this.$language.common.cartel + ' ' + this.$language.notice.article + ' ' + this.$language.common.search
				, title: this.$language.common.search
				, type: 'cartel'
				, not_header: true
				, not_footer: true
				, params: this.$route.params
			}
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_search: this.$storage.init({
				member_number: this.user.member_number
				, cartl_number: this.$route.params.idx
				, search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_search: false
			, items_bbs: []
			, view_type: ''
			, is_098: false
			, item_user: {}
			, is_add: false
			, max: false
			, is_more: false
			, item_more: {}
			, item_bbs: {}
			, items_member: []
			, is_item_detail: false
			, is_item_detail_comment: false
			, is_item_modify: false
			, items_cartel_bbs: []
		}
	}
	, computed: {
		txt_search: function(){
			let t = this.$language.common.error_empty_search_value

			if(this.is_search && this.items_bbs.length == 0){
				t = this.$language.common.no_search_data
			}

			return t
		}
		, text_empty: function (){
			let t = this.$language.common.error_empty_search_value
			if(this.item_search.search_value && this.items_cartel_bbs){
				t = this.$language.common.no_search_data
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: async function(){
			this.item_search.page_number = this.$language.base.page_number
			this.items_bbs = []
			// await this.getCartelSearch()
			await this.getMemberList()
			await this.getBbsList()
		}
		, getCartelSearch: async function(){
			try{
				if(!this.item_search.search_value){
					throw this.$language.common.error_empty_search_value
				}
				this.$set(this.item_search, 'page_number', 1)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_total_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
					}
					, type: true
				})

				if(result.success){

					this.items_bbs = result.data.cartl_post_search_result
					this.items_member = result.data.user_search_result

					this.$storage.setQuery(this.item_search)

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getBbsList: async function(page = 1){
			try{
				if(!this.item_search.search_value){
					// throw this.$language.common.error_empty_search_value
					return false
				}
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_bbs_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.post_list.length > 0){
						this.items_bbs = this.items_bbs.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}

					this.$storage.setQuery(this.item_search)

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}

		, getMemberList: async function(page = 1){
			try{
				if(!this.item_search.search_value){
					// throw this.$language.common.error_empty_search_value
					return false
				}
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_search_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.member_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			if(this.view_type == 'member'){
				this.view_type = ''
			}else{
				this.$storage.clearQuery()
				this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx}})
			}
		}


		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, onMore: function(item){
			this.is_more = true
			this.item_more = item
		}
		, mount: function(){
			this.is_more = false
			this.getSearch()
		}
		, toDetail: function(item){
			this.is_item_detail = true
			this.item_more = item
			//this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, toComment: function(item){
			this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}

		, on098: function(user){
			console.log('user', user)
			this.is_098 = true
			this.item_user = user
		}
		, update: function(item){

			this.item_more.sympaty_icon_code = item.sympaty_icon_code
			this.is_item_detail = false
			this.getBbs(this.item_more)
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}


		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							this.item_more.post_file_list[i].appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

					this.$set(this.items_cartel_bbs, this.item_more.index, this.item_more)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs[this.item_more.index] = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbs: function(item){
			switch (item.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					this.getCartelCheerInfo(item)
					break
				default:
					this.getCartelBbsInfo(item)
					break
			}
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false
			console.log('modify', item)
			this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}
	}
	, mounted() {
		this.$bus.$emit('onLoad', this.program)
		this.item_cartel = this.cartel
		this.getSearch()
	}
}
</script>