<template>

	<PopupLayer
		style=" z-index: 9999"
	>
		<template
			v-slot:body
		>
			<div
				v-if="is_on_support_result"
				class="position-absolute-full bg-white flex-column justify-space-between pa-20"
			>
				<div class="full-height flex-column justify-center items-center size-px-14">
					<div class="character2 width-100" style="height: 130px"></div>
					<div class="size-px-20 font-weight-bold">불렛 후원 완료</div>

					<div class="mt-20 width-100 text-center pa-30 size-px-18 bg-heart radius-20 color-red" style="background-size: 40px"> <b>{{ item_support.spnsh_point | makeComma}}</b> 불렛</div>

					<div class="mt-20"><b>{{ item_cartel.cartl_name }}</b>에게 </div>
					<div class="color-red mt-5"><b>{{ item_support.spnsh_point | makeComma}}개</b>의 <b>불렛</b>를 후원하였습니다.</div>
				</div>
				<div class="mt-auto">
					<button
						@click="offSupportResult"
						class="width-100 bg-blue-mafia pa-10 color-white size-px-16 radius-20"
					>확인</button>
				</div>
			</div>

			<div
				v-else
				class="radius-20 bg-white full-height flex-column overflow-hidden"
			>
				<div
					class=" pa-20 size-px-20 color-blue-mafia font-weight-bold justify-space-between items-center"
				>
					<div class="">불렛 후원하기</div>
					<button
						@click="offSupport"
					><v-icon>mdi-close-circle</v-icon></button>
				</div>

				<div
					class="bg-white pa-20 size-px-14 full-height flex-column top-line overflow-y-auto"
				>
					<div>
						<div class="justify-space-between">
							<div class="size-px-16 font-weight-bold">보유 불렛</div>
							<span class="size-px-13">{{ item_point.holding_point | makeComma }} 불렛</span>
						</div>
						<div class="mt-10">
							<div class="size-px-13 color-gray">최소 1개부터 최대 50,000개까지 가능</div>
							<div class="mt-10 input-box justify-space-between items-center color-red">
								<span class="bg-heart-only" style="width: 25px; height: 25px;"></span>
								<input
									v-model="item_support.spnsh_point"
									type="number"
									class="text-right color-red"
									:rules="$rules.max(item_support, 'point', 5)"
									placeholder="0"
								/>
								<span>불렛</span>
							</div>
							<div class="mt-10 input-box pa-20 color-gray">
									<textarea
										v-model="item_support.spnsh_message"
										class=" width-100"
										placeholder="후원과 함께 전송할 응원 메시지를 입력하세요."
										rows="5"
										maxlength="2000"
									></textarea>
							</div>
							<div class="mt-10 text-right color-gray"> {{ item_support.spnsh_message.length }} / 2,000</div>
							<div class="mt-10 ">
								<button
									@click="toPayment"
									class="btn_l btn_outline_blue width-100 color-blue justify-center items-center"
								><span class="inline-block bg-heart-only mr-10" style="width: 25px; height: 25px;"></span> 불렛 충전 하기</button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="bg-white mt-auto pt-10 "
				>
					<button
						class="width-100 bg-blue-mafia pa-10 color-white size-px-16"
						@click="onPin"
						:disabled="is_on_confirm"
					>후원하기</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'CartelSupport'
		,
		components: {PopupLayer},
		props: ['user', 'item_cartel']
		, data: function(){
			return {
				item_support: {
					spnsh_message: ''
					, spnsh_point: ''
				}
				, is_on_support_result: false
				, item_point: {

				}
				, pinnumber: ''
			}
		}
		, computed: {

			is_on_confirm: function(){
				let t = true
				if(this.item_support.spnsh_point > 0 && this.item_support.spnsh_message){
					t = false
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_user_point
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_point = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toPayment: function(){
				this.$bus.$emit('to', { name: 'PointList'})
			}
			, offSupport: function(){
				this.$emit('cancel')
			}
			, offSupportResult: function (){
				this.offSupport()
				this.is_on_support_result = false
			}
			, onPin: function(){
				if(!this.item_support.spnsh_point || this.item_support.spnsh_point == 0){
					this.$bus.$emit('notify', { type: 'error', message: '후원할 불렛를 입력하세요'})
				}else{
					this.$bus.$emit('onPin', 'check')

					this.$bus.$off('pinCallback')
					this.$bus.$on('pinCallback', (type, pin)=> {
						// console.log(type, pin)
						this.pinnumber = pin
						this.postCartelSupport()
					})
				}
			}
			, onSupportResult: function(){
				this.is_on_support_result = true
			}
			, postCartelSupport: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_user_support
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, spnsh_point: Number(this.item_support.spnsh_point)
							, spnsh_message: this.item_support.spnsh_message
							, pinnumber: this.pinnumber
						}
						, type: true
					})

					if(result.success){
						this.onSupportResult()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>