<template>
    <div
        ref="box-live"
        class="post_layout_view"
        v-if="show"
    >
        <ul class="bg-white">
            <li
                v-for="(live, l_index) in items_live"
                :key="'live_' + l_index"
                class="pa-10-20 "
                @click="toLive(live)"
            >
                <div
                    class="text-center flex-column justify-center overflow-hidden radius-20 position-relative box-live-img">
                    <img
						:src="live.cartl_chttg_img_url"
						@error="$bus.$emit('onErrorImage', $event)"
						style="width: 100%; height: 100%; object-fit: cover"
					/>
                    <label
						class="label label-danger position-absolute font-weight-bold"
						style="right: 10px; top: 10px"
					>LIVE</label>
                </div>
                <div class="mt-10 text-center size-px-16 font-weight-bold word-break-all">{{ live.cartl_chttg_room_name }}</div>
            </li>
        </ul>

        <transition
            name="slide-fade"
        >
            <div
                v-if="is_top"
                class="live-top"
            >
                <ul class="bg-white">
                    <li
                        v-for="(live, l_index) in items_live"
                        :key="'live_' + l_index"
                        class="pa-10"
                        @click="toLive(live)"
                    >
                        <div
                            class="text-center flex-column justify-center overflow-hidden radius-10 position-relative box-live-img">
                            <img
								:src="live.cartl_chttg_img_url"
								@error="$bus.$emit('onErrorImage', $event)"
								style="width: 100%; height: 100%; object-fit: cover"
							/>
                        </div>
                        <div class="mt-10 text-left size-px-16 font-weight-bold flex-row justify-space-between">
                            <label class="label label-danger font-weight-bold mr-10">LIVE</label>
                            <div
                                class="flex-1 overflow-hidden"
                            >
                                <Marquee class="size-px-16 font-weight-bold">
                                    <template
                                        v-slot:text
                                    >{{ live.cartl_chttg_room_name }}</template>
                                </Marquee>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import Marquee from "@/components/Marquee";

export default {
    name: 'LiveTop'
    , components: {Marquee}
	, props: ['user', 'cartel_info']
    , data: function () {
        return {
            show: false
            , items_live: [{
                cartl_chttg_img_url:''
                , cartl_chttg_room_desctn:''
                , cartl_chttg_room_div_code:''
                , cartl_chttg_room_name:''
                , cartl_chttg_room_number:''
                , cartl_chttg_room_state_code:''
                , cartl_chttg_room_state_name:''
                , participation_member_count:''
                , stremg_fg:''
            }]
            , item_search_live: {
                page: 1
                , list_cnt: 10
            }
            , is_top: false
        }
    }
    , methods: {

        getLiveChat: async function () {
            try {
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_live_list
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , page_number: this.item_search_live.page
                        , pagerecnum: this.item_search_live.list_cnt
                    }
                    , type: true
                })

                if (result.success) {
                    if (result.data.cartl_chttg_room_list.length > 0) {
                        this.show = true
                        this.items_live[0].cartl_chttg_img_url = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_img_url
                        this.items_live[0].cartl_chttg_room_desctn = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_desctn
                        this.items_live[0].cartl_chttg_room_div_code = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_div_code
                        this.items_live[0].cartl_chttg_room_name = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_name
                        this.items_live[0].cartl_chttg_room_number = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_number
                        this.items_live[0].cartl_chttg_room_state_code = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_state_code
                        this.items_live[0].cartl_chttg_room_state_name = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].cartl_chttg_room_state_name
                        this.items_live[0].participation_member_count = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].participation_member_count
                        this.items_live[0].stremg_fg = result.data.cartl_chttg_room_list[result.data.cartl_chttg_room_list.length - 1].stremg_fg

                        setTimeout(() => {
                            let live_top = this.$refs["box-live"].getBoundingClientRect().top + this.$refs["box-live"].clientHeight - 90

                            document.body.addEventListener('scroll', () => {
                                let scroll_top = document.body.scrollTop

                                this.is_top = scroll_top > live_top;
                            })
                        }, 100)
                    } else {
                        this.show = false
                    }

                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , toLive: function (item) {
            //this.$bus.$emit('to', { name: 'CartelLiveDetail', params: { idx: this.$route.params.idx, live_idx: item.cartl_chttg_room_number}})
            this.$bus.$emit('toLive', {
                name: 'CartelLiveDetail',
                params: {idx: this.$route.params.idx, live_idx: item.cartl_chttg_room_number}
            })
        }
    }
    , created() {
        this.getLiveChat()
    }
}
</script>

<style>
.live-top {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 11.5rem;
    z-index: 9;
    overflow: hidden;
    box-shadow: 1px 1px 1px 1px gray;
    background-color: var(--white);
}

.box-live-img {
    height: 25rem;
}

.live-top .box-live-img {
    height: 6rem;
}


.slide-fade-enter-active {
    transition: all .5s ease;
}

.slide-fade-leave-active {
    transition: all .0s ease;
}

.slide-fade-enter {
    transform: translateY(-30%);

    overflow: hidden;
}

.slide-fade-leave, .slide-fade-leave-to {
    transform: translateY(-10%);
    opacity: 0;
    overflow: hidden;
}
</style>