<template>
	<div class="">
		<div
			v-if="item_current.current_subscrp_plan_number"
			class="bg-gray-light pb-10"
		>
			<div
				class="pa-10-20 mt-10 bg-white "
			>
				<div class="size-px-18 font-weight-bold">구독중인 플랜</div>
				<div
					class="pa-20 bg-gray-light radius-20 mt-20"
				>
					<div class="flex-row justify-center">
						<div class=" square-100 radius-20 box-shadow"><img :src="item_current.current_subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" /></div>
					</div>
					<div class=" text-center mt-10 size-px-16 font-weight-bold">{{ item_current.current_subscrp_plan_name}}</div>
					<div class=" text-center mt-10 size-px-13 color-blue">{{ item_current.current_subscrp_enddate}} 구독 종료 예정</div>
				</div>
			</div>
		</div>

		<div
			v-if="items.length > 0"
			class="bg-gray-light pb-100"
		>
			<div class="size-px-18 font-weight-bold bg-white pa-10-20">구독 플랜</div>
			<ul>
				<li
					v-for="item in list_item"
					:key="'item_' + item.subscrp_plan_number"
					class="pa-20 mb-10 bg-white"
				>
					<div class="justify-space-between gap-10 items-center">
						<div class="square-100 radius-20"><img :src="item.img_physl_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" /></div>

						<div class="flex-1">
							<div>{{item.subscrp_plan_name}}</div>
							<div class="mt-10 color-blue"><img :src="require('@/assets/image/icon_won_blue.svg')" />{{ item.subscrp_amount | makeComma }}원</div>
						</div>
					</div>

					<div
						class="mt-10 bg-gray-light pa-10 radius-20"
					>
						<h4 class="flex-row items-center"><v-icon small>mdi-file-document</v-icon> {{ $language.subscribe.title_plan_info }} <!-- 구독 플랜 설명 --></h4>
						<hr class="under-line mt-10">
						<div
							:inner-html.prop="item.subscrp_plan_desctn | nl2br"
							class="size-px-14 mt-10 line-height-160"
							style="min-height: 100px"
						></div>
					</div>

					<div class="btn_wrap mt-30 ">
						<button
							v-if="is_master"
							class="btn_m btn_fill_blue"
							@click="toManage(item)"
						>플랜 관리</button>
						<button
							v-else-if="item_current.current_subscrp_plan_number"
							class="btn_m btn_fill_blue"
							@click="toSubscribe(item)"
						>구독 업그레이드</button>
						<button
							v-else
							class="btn_m btn_fill_blue"
							@click="toSubscribe(item)"
						>구독</button>
					</div>

				</li>
			</ul>
		</div>

		<Empty
			v-else
			class="mt-100 mb-50"
			text="등록된 구독플랜이 없습니다."
		>
		</Empty>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
export default {
	name: 'CartelMainSubscribe'
	,
	components: {Empty},
	props: ['user', 'cartel_info']
	, data: function(){
		return {
			items: []
			, item_current: {}
		}
	}

	, computed: {
		is_master: function(){
			let t = false
			if(this.cartel_info.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
				t = true
			}
			return t
		}
		, list_item: function(){
			let t = this.items
			t.sort( (a, b) => {
				if (a.subscrp_amount > b.subscrp_amount) {
					return 1;
				}
				if (a.subscrp_amount < b.subscrp_amount) {
					return -1;
				}
				// a must be equal to b
				return 0;
			})

			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				let url = this.$api_url.api_path.get_open_cartel_subscribe_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_subscribe_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.cartel_info.member_cartl_subscrp_plan_info.subscrp_plan_number ? this.cartel_info.member_cartl_subscrp_plan_info.subscrp_plan_number :this.cartel_info.member_cartl_subscrp_plan_info.payment_subscrp_plan_number
					}
					, type: true
				})

				if(result.success){
					this.item_current = result.data
					this.items = result.data.subscrp_plan_list
					console.log(this.items)
					console.log(this.items_plane)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toSubscribe: function(item){
			if(this.user.member_number){
				this.$bus.$emit('to',{ name: 'CartelSubscribeRequest', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number, type: this.item_current.current_subscrp_plan_number || null}})
			}else{
				this.$bus.$emit('onLogin')
			}
		}
		, toManage: function(item){
			this.$bus.$emit('to', { name: 'SubscribePlaneDetail', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number}})
		}
	}
	, created() {
		this.getData()
	}
}
</script>